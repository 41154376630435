class Overlay {
  constructor(overlayTrigger, overlayId, isMainNav) {
    this.overlay = document.querySelector('[data-overlay="' + overlayId + '"]');
    this.overlayTrigger = overlayTrigger;
    this.cover = document.querySelector('[data-overlay-cover]');
    this.isMainNav = isMainNav;

    this.padding = 6;

    // This matches the `margin-bottom` on `.overlay-trigger-group__item` within '_overlay-trigger-group.scss'
    this.overlayTriggerGroupBottomMargin = 32;
    this.parentTriggerGroup = this.overlayTrigger.closest('.overlay-trigger-group');

    this.top =
      this.overlayTrigger.getBoundingClientRect().top +
      this.overlayTrigger.offsetHeight +
      this.padding +
      window.pageYOffset;

    // The main nav's parent is `position: relative;`, so the overlay needs to be positioned a little differently
    this.mainNavTop = this.overlayTrigger.offsetHeight + this.padding;

    this.closeButton = this.overlay.querySelector('[data-overlay-close]');

    this.state = {
      open: false,
    };

    // Used in `sizeMainNav()` calculations
    this.ssi = document.querySelector('[data-ssi]');

    this.init();
  }

  init() {
    this.overlay.style.top = `${this.isMainNav ? this.mainNavTop : this.top}px`;

    this.overlayTrigger.addEventListener('click', () => {
      if (this.state.open) {
        this.close();
      } else {
        this.open();
      }
    });

    this.closeButton.addEventListener('click', () => {
      if (this.state.open) {
        this.close();
      }
    });
  }

  open() {
    if (!this.isMainNav) {
      this.cover.style.display = 'block';
    }
    this.overlay.classList.add('overlay--open');
    this.overlayTrigger.classList.add('active');
    this.state.open = true;

    /**
     * Set the overlay trigger's parent `.overlay-trigger-group` min-height to be equal to the
     * distance from the top of the `.overlay-trigger-group` to the bottom of the newly
     * opened `.overlay`. This way, if the overlay "spills out" of the container
     * and over the sticky SSI at the bottom, we create some white space between the
     * `.overlay-trigger-group` and that sticky SSI.
     *
     * Note _this specific logic_ doesn't apply for the main nav. Although,
     * we do something similar in the `else` block right after this.
     */
    if (!this.isMainNav && this.parentTriggerGroup) {
      let overlayBox = this.overlay.getBoundingClientRect();
      let parentBox = this.parentTriggerGroup.getBoundingClientRect();
      let overlayTriggerGroupMinHeight =
        Math.abs(overlayBox.bottom - parentBox.top) + this.overlayTriggerGroupBottomMargin;

      this.parentTriggerGroup.style.minHeight = `${overlayTriggerGroupMinHeight}px`;
    } else {
      /**
       * If we are opening the main-nav, then size it in case it intersects with
       * the sticky SSI below. The main-nav has an `overflow: auto` so it becomes
       * scrollable.
       */
      this.sizeMainNav();
    }
  }

  close() {
    if (this.isMainNav) {
      // Reset max-height from `sizeMainNav` call
      this.overlay.style.maxHeight = '';
    } else {
      this.cover.style.display = 'none';
    }
    this.overlay.classList.remove('overlay--open');
    this.overlayTrigger.classList.remove('active');
    this.state.open = false;

    // Reset the min-height on our parent overlay trigger group
    if (!this.isMainNav && this.parentTriggerGroup) {
      this.parentTriggerGroup.style.minHeight = '';
    }
  }

  sizeMainNav() {
    if (!this.isMainNav) {
      return;
    }

    let ssi_sizing = this.ssi.getBoundingClientRect();
    let main_nav_sizing = this.overlay.getBoundingClientRect();

    let main_nav_max_height = `${ssi_sizing.top - main_nav_sizing.top}px`;
    this.overlay.style.maxHeight = main_nav_max_height;
  }
}

export default Overlay;
