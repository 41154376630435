class SSI {

  constructor(){
    this.init.bind(this.init);

    this.ssi = document.querySelector('[data-ssi]');
    this.ssiTrigger = document.querySelector('[data-ssi-trigger]');
    this.body = document.body;
    
    this.openText = 'Show Less';
    this.closedText = 'Show More';
    this.ssiTriggerActiveClass = 'ssi__button--active';
    this.ssiOpenClass = 'ssi--open';
    this.noScrollClass = 'no-scroll';

    this.state = {
      open: false
    }

    this.init();
  }

  init(){
    
    this.ssiTrigger.addEventListener('click', () => {
      if(this.state.open){
        this.close();
      } else {
        this.open();
      }
    });
  }

  open(){
    this.body.classList.add(this.noScrollClass);
    this.ssi.classList.add(this.ssiOpenClass);
    this.ssiTrigger.classList.add(this.ssiTriggerActiveClass);
    this.ssiTrigger.textContent = this.openText;

    // Reset scroll position to top
    this.ssi.scrollTo(0, 0);

    // When we expand the SSI, force close any overlays that might be open
    if (window.inPageOverlays && window.inPageOverlays.length) {
      window.inPageOverlays.forEach(overlay => {
        overlay.close();
      })
    }

    this.state.open = true;
  }

  close(){
    this.body.classList.remove(this.noScrollClass);
    this.ssi.classList.remove(this.ssiOpenClass);

    // Reset scroll position to top
    this.ssi.scrollTo(0, 0);

    this.ssiTrigger.classList.remove(this.ssiTriggerActiveClass);
    this.ssiTrigger.textContent = this.closedText;

    this.state.open = false;
  }
}
module.exports = SSI;