import 'element-scroll-polyfill';
import './lib/polyfills/array.from';
import './lib/polyfills/array.find';
import './lib/polyfills/element.classlist';
import './lib/polyfills/element.closest';
import './lib/polyfills/nodelist.forEach';

import ssi from './lib/ssi';
import overlay from './lib/overlay';
import ready from './lib/util/ready';

import getInternetExplorerVersion from './lib/util/get-ie-version';

ready(() => {
	// add IE class if needed
	const ieVersion = getInternetExplorerVersion();
	if (ieVersion !== -1) document.body.classList.add(`ie-${ieVersion}`);

	let ssiComponent = new ssi();
	/**
	 * While the sticky should always initial to be closed, we sometimes
	 * see in firefox that is caches (due to the bfcache I think?) the
	 * previous JS state when navigating back with the back button.
	 *
	 * So, if you'd expand the SSI, scroll to the bottom, click on a PI link, then
	 * click "Back," the SSI scroll position would be all the way at the
	 * bottom, and you couldn't scroll back up.
	 *
	 * By _forcing_ the SSI closed (even if of course it already is) we
	 * resolve this issue.
	 */
	ssiComponent.close();

	let overlayTriggers = document.querySelectorAll('[data-overlay-trigger]');

	/**
	 * Initialize our overlays, and store each class instance in a global variable.
	 * This is so that later, when the SSI is expanded, we can close any open
	 * overlays that might be open. When the SSI is expanded and an overlay
	 * is open it floats on top of the expanded SSI is a bad way, so force them
	 * all closed.
	 */
	window.inPageOverlays = [];
	overlayTriggers.forEach((overlayItem) => {
		let overlay_instance = new overlay(
			overlayItem,
			overlayItem.dataset.overlayTrigger,
			overlayItem.dataset.overlayTrigger === 'menu'
		);
		window.inPageOverlays.push(overlay_instance);
	});

	/**
	 * Manually create a "mobile disallowed" overlay.
	 * The main reason we are doing this in JS is because of DOM structure:
	 * Tridion makes it hard to place HTML outside of the canonical #top, #middle, or #bottom.
	 * This is an easy way to accomplish that, plus it is more convenient
	 * to publish. If we ever move off Tridion, we should look to move this out of JS.
	 */
	const placeholder = document.createElement('div');
	placeholder.innerHTML = /* html */`<div class="mobile-disallowed">
		<div class="mobile-disallowed__inner">
			This site is not accessible from mobile phones.
			Please use a tablet, laptop, or desktop computer to access the site.
		</div>
	</div>`;
	const mobile_disallowed_element = placeholder.firstElementChild;

	// Insert as the first element so we can use the sibling selector (`~`) to hide other elements when the overlay is visible
	document.body.insertAdjacentElement('afterbegin', mobile_disallowed_element);
});
